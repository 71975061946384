import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui'
import { AVAILABLE_COUNTRIES } from '@/constants/countries'
import { AVAILABLE_LANGUAGES } from '@/i18n'
import { getAvailableCountry } from '@/lib/country'
import { cn, createPath } from '@/lib/utils'

type Props = {
  language: string
  country?: string
  pathname: string
  translations: {
    region: string
    language: string
  }
}

export const LanguageSelector = ({
  country,
  language,
  pathname,
  translations,
}: Props) => {
  const userCountry = AVAILABLE_COUNTRIES.find((c) => {
    const browserCountry = getAvailableCountry()

    if (country) {
      return c.code === country
    }

    return c.code === browserCountry
  })

  const search = import.meta.env.SSR ? '' : window.location.search

  const getPath = createPath(pathname, search)

  return (
    <Popover>
      <PopoverTrigger asChild>
        <button className="group flex items-center gap-2 transition-all hover:bg-neutral-gray-900 px-3 py-4 rounded-xl outline-none">
          <img
            className="h-6 w-6 border border-white rounded-lg object-cover"
            src={`https://flagcdn.com/h120/${userCountry?.code.toLocaleLowerCase()}.webp`}
            alt={userCountry?.name}
          />
          <p className="group-hover:text-primary transition-colors text-base text-white font-semibold uppercase">
            {language}
          </p>
        </button>
      </PopoverTrigger>
      <PopoverContent className="w-[512px] mt-5 rounded-3xl bg-rgb-popover-gray backdrop-blur-sm px-8 py-6">
        <div className="flex justify-around ">
          <div className="flex flex-col">
            <p className="text-3xl font-bold pb-8 text-white">
              {translations.region}
            </p>
            <div className="flex flex-col gap-5">
              {AVAILABLE_COUNTRIES.map((country) => (
                <a
                  key={country.name}
                  href={getPath({
                    country: country.code,
                    lang: language,
                  })}
                  className="flex items-center gap-3"
                >
                  <img
                    className="h-6 w-6 object-cover border border-white rounded-lg"
                    src={`https://flagcdn.com/h120/${country.code.toLocaleLowerCase()}.webp`}
                    alt={country.name}
                  />
                  <p
                    className={cn(
                      'text-2xl text-white transition-colors hover:text-primary-dark',
                      {
                        'text-primary': country.code === userCountry?.code,
                      },
                    )}
                  >
                    {country.name}
                  </p>
                </a>
              ))}
            </div>
          </div>
          <div className="flex flex-col">
            <p className="text-3xl text-white font-bold pb-8">
              {translations.language}
            </p>
            <div className="flex flex-col gap-5">
              {AVAILABLE_LANGUAGES.filter((lang) => !lang.includes('-')).map(
                (lang) => (
                  <a
                    key={lang}
                    href={getPath({
                      country,
                      lang,
                    })}
                    className={cn(
                      'flex text-2xl text-white transition-colors hover:text-primary-dark uppercase',
                      {
                        'text-primary': lang === language,
                      },
                    )}
                  >
                    {lang}
                  </a>
                ),
              )}
            </div>
          </div>
        </div>
      </PopoverContent>
    </Popover>
  )
}
